'use strict';

translateConfig.$inject = ['$translateProvider'];

function translateConfig($translateProvider) {
    var preferredLanguage = (navigator.languages ? navigator.languages[0] : navigator.language);
    $translateProvider
        .useSanitizeValueStrategy('sanitize')
        .useStaticFilesLoader({
            prefix: '/i18n/',
            suffix: '.json'
        })
        .preferredLanguage(preferredLanguage)
        .fallbackLanguage('en-US');
}
